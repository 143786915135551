import React, { useEffect, useState } from 'react';

const Integraciones = ({ section }) => {
  const [qrCode, setQrCode] = useState('');
  const [apiToken, setApiToken] = useState('');

  // Obtener el código QR de WhatsApp cuando la sección es WhatsApp
  useEffect(() => {
    if (section === 'whatsapp') {
      fetch('/api/integraciones/whatsapp/qr', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'), // Asume que el token está almacenado en localStorage
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.qrCode) {
            setQrCode(data.qrCode);
          }
        })
        .catch(error => console.error('Error al obtener el código QR:', error));
    }
  }, [section]);

  // Manejar el guardado del token de Envia.com
  const handleSaveApiToken = () => {
    fetch('/api/integraciones/envios', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'), // Asume que el token está en localStorage
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ apiToken }),
    })
      .then(response => response.json())
      .then(data => {
        alert(data.message);
      })
      .catch(error => console.error('Error al guardar el token API:', error));
  };

  return (
    <div>
      {/* Integración con WhatsApp */}
      {section === 'whatsapp' && (
        <div>
          <h1>Integración con WhatsApp</h1>
          {qrCode ? (
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(qrCode)}&size=200x200`}
              alt="QR Code"
            />
          ) : (
            <p>Escanea el código QR para conectar tu WhatsApp.</p>
          )}
        </div>
      )}

      {/* Integración con Envia.com */}
      {section === 'envios' && (
        <div>
          <h1>Integración con Envíos</h1>
          <input
            type="text"
            placeholder="Token API de Envia.com"
            value={apiToken}
            onChange={(e) => setApiToken(e.target.value)}
          />
          <button onClick={handleSaveApiToken}>Guardar Token API</button>
        </div>
      )}
    </div>
  );
};

export default Integraciones;
