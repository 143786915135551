import React, { useState } from 'react';

const Mensajes = () => {
  const [confirmacionPedido, setConfirmacionPedido] = useState('');
  const [mensajePersonalizado, setMensajePersonalizado] = useState('');

  const handleSaveMessages = () => {
    fetch('/api/mensajes', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'), // Asume que el token está en localStorage
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ confirmacionPedido, mensajePersonalizado }),
    })
      .then(response => response.json())
      .then(data => {
        alert(data.message);
      })
      .catch(error => console.error('Error al guardar configuraciones de mensajes:', error));
  };

  return (
    <div>
      <h1>Configuración de Mensajes</h1>
      <div>
        <label>Mensaje de Confirmación de Pedido:</label>
        <textarea
          value={confirmacionPedido}
          onChange={(e) => setConfirmacionPedido(e.target.value)}
        />
      </div>
      <div>
        <label>Mensaje Personalizado:</label>
        <textarea
          value={mensajePersonalizado}
          onChange={(e) => setMensajePersonalizado(e.target.value)}
        />
      </div>
      <button onClick={handleSaveMessages}>Guardar Configuración</button>
    </div>
  );
};

export default Mensajes;
