import React, { useState } from 'react';
import { Frame, Navigation } from '@shopify/polaris';
import Dashboard from './components/Dashboard';
import Integraciones from './components/Integraciones';
import Mensajes from './components/Mensajes';

const App = () => {
  const [selectedPage, setSelectedPage] = useState('dashboard');

  return (
    <Frame
      navigation={
        <Navigation location="/">
          <Navigation.Section
            items={[
              {
                label: 'Panel de Control',
                onClick: () => setSelectedPage('dashboard'),
              },
              {
                label: 'Integraciones',
                items: [
                  { label: 'WhatsApp', onClick: () => setSelectedPage('whatsapp') },
                  { label: 'Envíos', onClick: () => setSelectedPage('envios') },
                ],
              },
              {
                label: 'Mensajes',
                onClick: () => setSelectedPage('mensajes'),
              },
            ]}
          />
        </Navigation>
      }
    >
      {selectedPage === 'dashboard' && <Dashboard />}
      {selectedPage === 'whatsapp' && <Integraciones section="whatsapp" />}
      {selectedPage === 'envios' && <Integraciones section="envios" />}
      {selectedPage === 'mensajes' && <Mensajes />}
    </Frame>
  );
};

export default App;
